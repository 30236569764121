.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-item .show .dropdown {
  background-color: #000000;
}

a.dropdown-item {
  color: #ffffff;
}

.dropdown-item:hover {
  background-color: #00000014;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #94A1B7;
  opacity: 1;
  font-size: 16px;
  font-family: sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#nav-dropdown {
  color: #94A1B7;
  font-size: 17px;
  font-family: Sofia Pro;
  border-radius: 10px;

  /* background-color: red; */
}

.dropdown-toggle::after {
  display: none !important;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

#nav-dropdown1 {
  font-size: 14px;
  font-family: Sofia Pro;
  font-weight: 400;
  /* background-color: red; */
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1rem;
  clear: both;
  font-family: Sofia Pro !important;

  font-weight: 570 !important;
  color: rgb(38, 49, 67) !important;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.MuiMenuItem-root {
  font-family: Sofia Pro !important;
}

.MuiSelect-root {
  font-family: Sofia Pro !important;

}



.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;

  min-width: 8rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff ! important;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
}

.dropdown-menu ::selection {
  color: #fff;
  background: white ! important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: Sofia pro !important;
}

/* .dropdown-menu show {
  width: 25px;
  height: 20px;
} */

#nav-dropdown:hover {
  color: rgb(62, 158, 214);
  background-color: #263143;
}

.active {
  color: white !important;

}

.textField_label {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollhost {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  /*just hides the scrollbar for firefox */
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost ::-moz-scrollbar {
  display: none;

}