
@font-face {
  font-family: 'Sofia Pro';
  src: url('./fonts/Sofiapro.ttf') format("truetype");
}

body {
   margin: 0;
   font-family: "Sofia Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: 'Sofia Pro'
}
