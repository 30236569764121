.section6{
    display: flex;
    width: 100%;
    background-color: #bacaee;
    background-image: linear-gradient(150deg,#42d9ff,#378dff 22%,#5141ff 50%,#e95aff 75%,#ffb4d9);
    background-clip: content-box;
    padding-top:0px;
    -webkit-text-fill-color: inherit;
}
.section6_title{
    color: #fff;
    font-size: 70px;
    font-family: 'Sofia Pro';
    font-weight: 700;
}

.section6_left{
    padding:100px
}
.section6_right_container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.responsive_section6_left{
    display: none;
}

.section6_right{
  display: flex;
  flex: 1;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.section6_right_subtitle{
    color: #fff;
    font-size: 19px;
    line-height: 30px;
    font-weight: 500;
    font-family: 'Sofia Pro';
    font-weight: 500;
}
.section6_btn_container{
    display: flex;
    /* flex: 1; */
    flex-direction: row;
}
.section6_btn{
    width: 35%;
    height: 60px;
    border-style: none;
    padding: 10px;
    font-family:'Sofia Pro';
    font-size: 18px;
    font-weight: 500;
    color: #2f6dff;
    border-radius:8px;
}
.section6_right_btn{
    width: 35%;
    height: 60px;
    padding: 10px;
    font-family:'Sofia Pro';
    font-size: 18px;
    background-color: transparent;
    font-weight: 500;
    border:2px solid hsla(0,0%,100%,.5);
    color: #fff;
    position: relative;
    left: 20px;
    border-radius:8px;
}


/* Responsive */

@media (min-width:320px) and (max-width:550px){
    .section6_left{
        display: none;
    }
    .responsive_section6_left{
        display: flex;
        padding: 40px;
    }
    .section6_title{
        font-size: 50px;
        text-align: center;
    }
    .section6_right{
        width: 100%;
    }
    .section6_right_subtitle{
        text-align: center;
        margin-bottom: 12px;
    }
    .section6_btn_container{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin-bottom:45px;
    }
}