/* section 1 */
* {
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
}

html {
  width: 100%;
}

/* hide scrollbar but allow scrolling */
body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.section_1 {
  background-color: rgb(10, 25, 41);
  padding-top: 150px;
  padding-bottom: 60px;

}

.modal-title {
  width: 100%
}

.btn-close {
  display: none;
}

.modal-content {
  border: 1px solid #2f6dff;
  border-radius: 10px;
  background-color: rgb(10, 25, 41);
}

.modal-header {
  border-bottom: none;
}

.fBauNC {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.modal-body {
  border-bottom: none;
  width: 100%;
}

.jlthYR {
  border-bottom: none !important;
}


.section_1_title {
  color: #fff;
  font-size: 54px;
  text-align: left;
  font-family: 'Sofia Pro';
  margin-bottom: "20px";
  font-weight: 700;
}




#subtitle {
  margin-top: 8px;
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0px;
  font-family: 'Sofia Pro';
  color: rgb(102, 178, 255);
  ;
  font-size: 22px;
  font-weight: 400;
}

.section_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.button-wrapper {
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  align-items: center;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
  position: relative;
  right: 15px;

}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: rgb(102, 178, 255);
  color: #fff;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}



.button-secondary {
  display: flex;
  margin-right: 0;
  margin-left: 20px;
  padding: 18px 20px 19px;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Sofia Pro';
  flex: 0 0 auto;
  border: 2px solid #2f6dff;
  border-radius: 10px;
  background-color: #fff;
  color: #2f6dff;
  font-weight: 600;
}

.section_end {
  display: flex;
  flex-direction: row;

}

.view {
  margin-top: 25px;
}

.views_title {
  color: #fff;
  font-size: 35px;
  font-family: 'Sofia Pro';
  font-weight: 600;
}

.views_subtitle {
  color: rgb(102, 178, 255);
  font-size: 13px;
  font-family: 'Sofia Pro';
  font-weight: 500;
  letter-spacing: 3px;
}

@media (min-width:375px) and (max-width:550px) {
  .responsive_container {
    display: flex;
    flex: 1;
    flex-direction: column
  }

  .section_1_title {
    text-align: center;
  }

  #subtitle {
    position: relative;
    top: 20px;
    text-align: center;
  }

  .responsive_btn_container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 10px;
    width: 70%;
    text-align: center;
  }

  .button-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 50px;
  }

  .button-secondary {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .view {
    margin-top: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .views_title {
    text-align: center;
  }

  .views_subtitle {
    width: 100%;
    text-align: center;

  }

  .responsive_col {
    width: 100%;
  }

  .hero-image {
    width: 100%;
  }

}

/* .hero-paragraph .w-list-unstyled{
  list-style: none;
}  */