.section5{
    display:flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;
    padding-top: 100px;
    background-color: rgb(10, 25, 41);
    padding-bottom: 70px;
}
.section_5_title{
    font-size: 44px;
    line-height:1.7;
    font-family:'Sofia Pro';
    font-weight: 700;
    color: #fff;
    letter-spacing: -.03em;
    margin-bottom: 20px;
  }
  .section5_container{
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-top: 10px;
      /* justify-content: space-between; */
  }
  .section_5_card_box{
      display: flex;
      flex-direction: column;
    
  }
  
  .section_5_card_title{
    margin-top: 24px;
    margin-bottom: 17px;
    color: #fff;
    font-size: 22px;
    line-height: 1;
    text-align: center;
    font-weight: 700;
    font-family:'Sofia Pro';
  }
  .section_5_subtitle{
    color: rgb(102, 178, 255);
    font-size: 20px;
    line-height: 1.7;
    text-align: center;
    font-family:'Sofia Pro';
}
 
 .section_5_steps{
    color: rgb(102, 178, 255);
    font-size: 20px;
    line-height: 1;
    text-align: center;
    font-family:'Sofia Pro';
 }

.section_5_middle_card_box{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}
.line-column {
    display: flex;
    padding: 70px;
    align-items: center;
}
.step-image{
    height: auto;
    max-width: 80%;
    min-height: auto;
    margin-left: 20px;
    margin-bottom: 16px;
    border-radius: 8px;
}
.responsive_section_5_title_container{
  display: none;
}


@media (min-width:320px) and (max-width:550px){
   
   .section5{
        padding:12px;
        padding-top:30px;
   }
   .section_5_title_container{
       display: none;
   }

   .section_5_title_container{
       display: none;
   }

   .responsive_section_5_title_container{
       display: flex;
   }
   .section_5_title{
       text-align: center;
       line-height: 1.3;
   }
   .section5_container{
       display: flex;
       flex: 1;
       flex-direction: column;
   }
   .line-column{
       display: none;
   }
   .section_5_steps{
      padding-bottom:65px;
   }

   .step-image{
       max-width: 100%;
       width: 90%;
    }
    .section_5_card_box{
        margin-bottom: 70px;
    }
   .section_5_card_image{
       width: 100%;
   }
}