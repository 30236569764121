.footer{
    padding-bottom: 140px;
    padding: 80px;
    background-color: rgb(10, 25, 41);
}

.Footer_Container{
    width: 100%;
    height: fit-content;
    padding-top: 50px;
}

.footer_left_container{
    display: flex;
    flex:1;
    flex-direction: column;
}
.footer_left_title{
    margin-top: 0;
    display: block;
    margin-bottom: 24px;
    clear: left;
    color: #fff;
    font-family: 'Sofia Pro';
    font-size: 18px;
    line-height: 1.7;
    letter-spacing: -.02em;
}
.footer_left_title_container{
    position: relative;
    top: 15px;
}
.footer_left_email{
    clear: left;
    color: rgb(102, 178, 255);
    font-family:'Sofia Pro';
    font-size: 15px;
    font-weight: 600; 
}
.footer_right_title{
    margin-bottom: 20px;
    color: #fff;
    font-family:'Sofia Pro';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;



}
.footer_right_link{
    margin-bottom: 12px;
    padding-top: 8px;
    padding-right: 6px;
   padding-bottom: 8px;
   font-family:'Sofia Pro';
    color: rgb(102, 178, 255);
    font-size: 16px;
    text-decoration: none;
}

@media (min-width:320px ) and (max-width:550px) {

    .footer{
        padding:20px;
        padding-top:85px
    }

    .responsive_footer{
        display: flex;
        flex-direction: column;
    }
}