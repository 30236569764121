.section_container{
    display:flex;
    flex:1;
    align-items: center;
    flex-direction:column;
    padding-top: 60px;
    padding-bottom: 70px;
    background-color: rgb(10, 25, 41);
}

.section_3_title{
  font-size: 44px;
  font-family: 'Sofia Pro';
  font-weight: 700;
  color: #fff;
  letter-spacing: -.03em;
  line-height: 1.5;
  margin-bottom: 20px;
}
.section_3_subtitle{
    color: rgb(102, 178, 255);
    font-size: 20px;
    line-height: 1.8;
    font-family: 'Sofia Pro';
}
.section3_card_container{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.section3_card_box{
    display: flex;
    flex-direction: column;
}
.cards-image{
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    width: 70%;
    height: 100%;
    max-width: none;
    border-radius: 8px;
    object-fit: cover;
    object-position: 50% 50%;
}
.card_title{
    margin-top: 24px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 22px;
    font-family: 'Sofia Pro';
    line-height: 1;
    font-weight: 700;

}
.card_subtitle_width{
    width: 70%;
}
.card_subtitle{
    color: rgb(102, 178, 255);
    font-size: 18px;
    font-family: 'Sofia Pro';
    font-weight: 400;
    line-height: 1.7;
}
.card_link{
    color:#fff;
    font-size: 15px;
    font-family: 'Sofia Pro';
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1.7;
}

/* Responsive */

@media (min-width:320px) and (max-width:550px) {

    .section_container{
        padding: 15px;
    }

    .section_3_title{
        text-align: center;
    }
    .section_3_subtitle{
        text-align: center;
        line-height: 1.8;
    }
    .section3_card_container{
        display: flex;
        flex: 1;
        flex-direction: column;
     }
     .section3_card_box{
         display: flex;
         flex: 1;
         justify-content: center;
         align-items: center;
         padding-bottom: 40px;
     }
     .cards-image{
        width: 95%;
        margin-left:10px;
      }
    
      .card_subtitle_width{
          width: 100%;
      }
      .card_subtitle{
          text-align: center;
      }
      .card_link{
          text-align: center;
      }
}