.section2{
    display: flex;
    flex:1;
    width:"100%";
    height:60;
    background-color:rgb(10, 25, 41);
    padding: 40px;
    justify-content: flex-start;
    padding-top:50px;
    padding-bottom: 30px;
}
.section_2_title{
    font-size: 22px;
    color: #fff;
    font-family: 'Sofia Pro';
    font-weight: 700;
}
.responsive_container{
    display: flex;
    flex:1;
    width:"100%";
    flex-direction: row;
}
.image_container{
    display: flex;
    flex-direction: row;
    position: relative;
    left: 40px;
    top: 0px;
}
.brand_image{
    width: 60%;
}

@media (min-width:375px) and (max-width:550px) {
   
    .section2{
        padding: 9px;
    }

    .responsive_container{
        display: flex;
        flex:1;
        width:"100%";
        flex-direction: column;
    }
    .brand_image{
        width: 100%;
    }
    .responsive_image_container{
        display: none;
        /* flex: 1;
        flex-direction: column;
        width: 100%; */
    }
    .image_container{
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
    }
}



