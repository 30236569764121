.section4{
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 50px;
    background-color: rgb(10, 25, 41)
}
.section_4_subtitle{
    color: rgb(102, 178, 255);
    font-size: 18px;
    font-family:'Sofia Pro';
}
.image-11{
    display: flex;
    position: relative;
    right: 40px;
    max-width: 56%;
    border-radius: 10px;

}
.image_container{
    display: flex;
    width:88%;
    justify-content: center;
    align-items: center;
}

@media ( min-width:320px) and (max-width:550px){
    .section4{
        padding-top: 70px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 50px;
    }

    .image_container{
        display: none;
    }

    .image-11{
        max-width: 100%;
        position: relative;
        top: 0;

        /* right: 40px; */
    }
}
